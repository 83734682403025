import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../components/layout/Entry';

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const dashboard = [
  {
    path: '/',
    component: Layout,
    redirect: '/login',
  },
  {
    path: '/login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    redirect: '/login'
  },
];

//系统通知
const notices = [
  {
    path: '/notices',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'notice',
        component: () => import('../views/notice/index.vue'),
        meta: {title: '系统通知'}
      },
    ]
  }
];
//收银
const cashier = [
  {
    path: '/cashiers',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'cashier',
        component: () => import('../views/cashier/cashier.vue'),
        meta: {title: '收银'}
      },
      // 开单
      {
        path: 'billing',
        component: () => import('../views/billing/billing.vue'),
        meta: {title: '开单'}
      },
      // 重开单
      {
        path: 'reorder',
        component: () => import('../views/billing/reorder.vue'),
        meta: {title: '重开单'}
      },
      // 欠款顾客列表
      {
        path: 'arrears',
        component: () => import('../views/cashier/arrears/arrears.vue'),
        meta: {title: '欠款顾客列表'}
      },
    ]
  },
];
//定金管理
const deposit = [
  {
    path: '/deposits',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'deposit',
        component: () => import('../views/deposit/entry.vue'),
        meta: {title: '定金管理'}
      },
      {
        path: 'djkd',
        component: () => import('../views/deposit/dj/billing.vue'),
        meta: {title: '定金开单'}
      },
      {
        path: 'qkkd',
        component: () => import('../views/deposit/qk/billing.vue'),
        meta: {title: '全款开单'}
      },
    ]
  },
];
//服务商品
const serviceSkus = [
  {
    path: '/serviceSkus',
    component: Layout,
    children: [
      {
        path: 'serviceSku',
        component: () => import('../views/servicesku/entry.vue'),
        meta: {title: '服务商品'}
      },
    ]
  },
];


//顾客
const customer = [
  {
    path: '/customers',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'customer',
        component: () => import('../views/customer/entry.vue'),
        meta: {title: '顾客'}
      },
      {
        path: 'saveMember',
        component: () => import('../views/customer/member/insert.vue'),
        meta: {title: '新增顾客'}
      },
      {
        path: 'memberInfo',
        component: () => import('../views/customer/detail.vue'),
        meta: {title: '顾客详情'}
      },
    ]
  },
];


//套餐
const packages = [
  {
    path: '/packages',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'package',
        component: () => import('../views/packages/entry.vue'),
        meta: {title: '套餐'}
      },
    ]
  },
];


//品项
const items = [
  {
    path: '/items',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'px',
        component: () => import('../views/px/entry.vue'),
        meta: {title: '品项'}
      },
    ]
  },
];

//开支
const expenses = [
  {
    path: '/expenses',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'kz',
        component: () => import('../views/expenses/entry.vue'),
        meta: {title: '开支'}
      },
    ]
  },
];


//流水
const turnover = [
  {
    path: '/turnovers',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'turnover',
        component: () => import('../views/turnover/entry.vue'),
        meta: {title: '流水'}
      },
      {
        path: 'orderDetails',
        component: () => import('../views/turnover/orderDetails.vue'),
        meta: {title: '订单详情'}
      },
      {
        path: 'cgDetails',
        component: () => import('../views/turnover/cgDetails.vue'),
        meta: {title: '开支订单详情'}
      },
    ]
  },
];


//卡类
const card = [
  {
    path: '/cards',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'card',
        component: () => import('../views/cards/entry.vue'),
        meta: {title: '卡类'}
      },
    ]
  },
];


//库存
const stock = [
  {
    path: '/stocks',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'ck',
        component: () => import('../views/stock/ck.vue'),
        meta: {title: '出库'}
      },
    ]
  },
];


//小计
const smallmeter = [
  {
    path: '/smallmeters',
    component: Layout,
    redirect: '/login',
    children: [
      {
        path: 'smallmeter',
        component: () => import('../views/smallmeter/entry.vue'),
        meta: {title: '小计'}
      },
    ]
  },
];

//预约
const appointment = [
  {
    path: '/appointment',
    component: Layout,
    children: [
      {
        path: 'yyrecord',
        component: () => import('../views/appointment/entry.vue'),
        meta: {title: '预约'}
      },
    ]
  },
];


const routes = [...dashboard, ...customer, ...packages, ...turnover, ...stock, ...card, ...cashier, ...smallmeter, ...items, ...expenses, ...serviceSkus, ...notices,...deposit,...appointment];


export default new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})
