<template>
  <div class="sidebar-left">
    <div class="sidebar-container">
      <div class="mdn-item" @click="toPopover">
        <!--        <div class="wb-btn">-->
        <!--          <i class="el-icon-switch-button fonticon" @click="toLogin">-->
        <!--            <span style="margin-left: 6px;font-size: 10px;">退出</span>-->
        <!--          </i>-->
        <!--        </div>-->
        <p class="md-name">{{ userName }}</p>
      </div>
      <ul>
        <router-link tag="li" to="/cashiers/cashier" class="sidebar-item">
          <i class="el-icon-postcard fonticon"></i>
          <span class="txt">快捷收银</span>
        </router-link>
        <router-link tag="li" to="/appointment/yyrecord"  class="sidebar-item">
          <i class="el-icon-suitcase fonticon"></i>
          <span class="txt">预约</span>
        </router-link>
        <router-link tag="li" to="/serviceSkus/serviceSku" v-if="isSpec" class="sidebar-item">
          <i class="el-icon-suitcase fonticon"></i>
          <span class="txt">商品服务</span>
        </router-link>
        <!--        <router-link tag="li" to="/cashiers/cashier" class="sidebar-item">-->
        <!--          <i class="el-icon-s-home fonticon"></i>-->
        <!--          <span class="txt">房间大厅</span>-->
        <!--        </router-link>-->
        <router-link tag="li" to="/cards/card" class="sidebar-item">
          <i class="el-icon-bank-card fonticon"></i>
          <span class="txt">会员卡</span>
        </router-link>
        <router-link tag="li" to="/packages/package" class="sidebar-item">
          <i class="el-icon-present fonticon"></i>
          <span class="txt">套餐</span>
        </router-link>
        <router-link tag="li" to="/items/px" class="sidebar-item" v-if="isPx">
          <i class="el-icon-suitcase fonticon"></i>
          <span class="txt">品项</span>
        </router-link>
        <router-link tag="li" to="/expenses/kz" class="sidebar-item">
          <i class="el-icon-coin fonticon"></i>
          <span class="txt">开支</span>
        </router-link>
        <router-link tag="li" to="/stocks/ck" class="sidebar-item">
          <i class="el-icon-s-goods fonticon"></i>
          <span class="txt">出库</span>
        </router-link>
        <router-link tag="li" to="/turnovers/turnover" class="sidebar-item">
          <i class="el-icon-tickets fonticon"></i>
          <span class="txt">流水</span>
        </router-link>
        <router-link tag="li" to="/smallmeters/smallmeter" class="sidebar-item">
          <i class="el-icon-orange fonticon"></i>
          <span class="txt">小计</span>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
  import {getSwName, getToken} from "../../utils/auth";
  import store from '@/store'
  import getters from "../../store/getters";
  export default {
    name: "",
    data() {
      return {
        userName: "",
        logoUrl: "../../../static/img/log.jpg",
        member: {},
        isPx: false,
        isSpec: false,
        visible: false
      };
    },
    async created() {
      this.userName = getSwName();
      this.getMenus();
    },
    methods: {
      toPopover() {
        this.visible = !this.visible
        this.$emit('toPopover', this.visible);
      },
      setPopover(val) {
        this.visible = val
      },

      async getMenus() {
        let res = await this.$get("/sw/getMenus", {token: getToken()})
        let permissionList=res.permissionList
        this.$store.dispatch('setRoles', permissionList)
        this.isPx = res.isPx
        this.isSpec = res.isSpec
      },

    },
  };
</script>

<style scoped>
  .sidebar-left {
    background: #51576D;
    left: 0;
    position: relative;
    height: 100%;
    width: auto;
  }
  .mdn-item{
    text-align:center;
    padding: 10px 0px;
    cursor: pointer;
    background-color: #8558FA;
    margin-bottom: 30px;
  }

  .md-name {
    font-size: 10px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 8px;
    color: #ffffff;
  }

  .sidebar-container {
    height: 100%;
  }

  .sidebar-item {
    width: 98px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 68px;
    cursor: pointer;
  }

  .sidebar-item .fonticon {
    font-size: 28px;
    color: #EEEFEE;
  }

  .wb-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 98px;
    margin-left: -6px;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .wb-btn .fonticon {
    line-height: 28px;
    font-size: 13px;
    color: #EEEFEE;
    font-weight: bold;

  }

  .sidebar-item .txt {
    line-height: 1.5;
    font-size: 13px;
    font-weight: bold;
    color: rgb(185, 185, 185);
  }

  .sidebar-item.router-link-active .txt,
  .sidebar-item.router-link-active .fonticon {
    color: #fff;
  }

  .sidebar-item.router-link-active {
    background-color: #494E62;
  }


  .sidebar-item:hover .fonticon,
  .sidebar-item:hover .txt {
    color: #fff;
  }

</style>
